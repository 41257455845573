<app-dialog-logo-header (closeClick)="closeDialog.emit()" [text]="titleText"></app-dialog-logo-header>

<ng-container *ngIf="userType === 'partner' && !is_info_accept">
  <app-dialog-body>
    <p *ngIf="partnerInfo" class="partner-info">
      Вы регистрируете Соглашение о партнерстве с компанией <span class="nobr">ЭВА ПРОДАКТ</span> в организации
      наставника <span class="bold">{{ partnerInfo.name }}</span> (ID
      <span class="bold">{{ partnerInfo.visibleId }})</span>
    </p>

    <div class="info">
      <p>Перед тем как продолжить регистрацию, внимательно ознакомьтесь с информацией, представленной ниже.</p>
      <p>
        В соответствии со Стандартами и правилами компании человек может иметь один действующий контракт.
        Зарегистрированный партнер может иметь одно Соглашение с компанией. Если у кандидата уже есть действующий
        контракт - зарегистрировать новое соглашение он может только, аннулировав прежнее, то есть через 6 месяцев
        неактивности.
      </p>
      <p>
        При обнаружении нарушений второй контракт возвращается под аккаунт, зарегистрированный ранее. При этом, если
        менеджер не хочет продолжать работу на первом договоре, зарегистрировать новое соглашение возможно не ранее чем
        через 6 месяцев неактивности.
      </p>
      <p>
        При желании зарегистрировать Семейное Соглашение - Вы сможете это сделать после индивидуальной регистрации.
        Супруги могут иметь индивидуальные контракты. При этом их контракты должны быть зарегистрированы под одним
        наставником или друг под другом.
      </p>
      <p>
        <strong>ПЕРИОД АКТИВАЦИИ ПАРТНЕРА:</strong> до конца текущего месяца (периода) + следующий. После регистрации
        Вам предоставляется время для активации контракта. За это время Вам необходимо сделать в один из 2 календарных
        месяцев (текущий или следующий) Личный объем не менее 50 баллов. Если объем 50 баллов не выполнен - Соглашение о
        партнерстве аннулируется по истечении времени для активации контракта.
      </p>
    </div>
  </app-dialog-body>

  <div mat-dialog-actions class="controls">
    <app-dialog-footer>
      <div class="buttons">
        <button (click)="handleClickAcceptInfo()" type="submit" app-button color="dark" size="large">Продолжить</button>
        <ng-content></ng-content>
      </div>
    </app-dialog-footer>
  </div>
</ng-container>

<ng-container *ngIf="userType == undefined || userType === 'client' || is_info_accept">
  <app-dialog-body>
    <p *ngIf="partnerInfo" class="partner-info">
      Вы регистрируете Соглашение о партнерстве с компанией <span class="nobr">ЭВА ПРОДАКТ</span> в организации
      наставника <span class="bold">{{ partnerInfo.name }}</span> (ID
      <span class="bold">{{ partnerInfo.visibleId }})</span>
    </p>

    <div *ngIf="showUserCategory" class="category">
      <h5 class="h5">Категория</h5>
      <app-tabs
        [selectedOption]="selectedOption"
        [options]="['Партнер', 'Клиент']"
        (selectedOptionChange)="selectedOption = $event!"
        justify="start"
      ></app-tabs>
    </div>

    <form #templateForm="ngForm" (ngSubmit)="handleSubmit(templateForm)" id="register-form">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Имя</mat-label>
        <input name="name" [(ngModel)]="form.name" matInput required />
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Фамилия</mat-label>
        <input name="surname" [(ngModel)]="form.surname" matInput required />
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Отчество</mat-label>
        <input name="patronymic" [(ngModel)]="form.patronymic" matInput />
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Пол</mat-label>
        <mat-select name="sex" [(ngModel)]="form.sex">
          <mat-option *ngFor="let option of sexTypeOptions" [value]="option[0]"> {{ option[1] }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Логин</mat-label>
        <input name="login" [(ngModel)]="form.login" matInput required />
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Email</mat-label>
        <input
          placeholder="email@mail.ru"
          name="email"
          [(ngModel)]="form.email"
          #email="ngModel"
          matInput
          required
          email
        />
        <mat-error *ngIf="email.hasError('required')">Поле должно быть заполнено</mat-error>
        <mat-error *ngIf="email.hasError('email')">Неверный формат Email</mat-error>
      </mat-form-field>

      <app-flex jc="space-between">
        <app-international-phone-input
          name="phone"
          [(ngModel)]="form.phone"
          #phone="ngModel"
          required
          [error]="phone.hasError('required') ? 'Поле должно быть заполнено' : null"
        ></app-international-phone-input>

        <mat-form-field appearance="fill">
          <mat-label>Дата рождения</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            name="birthday"
            [(ngModel)]="form.birthday"
            #birthday="ngModel"
            required
          />
          <mat-hint>дд.мм.гггг</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="birthday.hasError('required')">Поле должно быть заполнено</mat-error>
        </mat-form-field>
      </app-flex>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Страна</mat-label>
        <app-country-search
          name="country"
          [(ngModel)]="form.country"
          (ngModelChange)="handleCountryChange()"
          [countries]="countries$ | async"
          placeholder="Начните вводить"
          required
        >
        </app-country-search>
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>
      <mat-form-field
        appearance="fill"
        class="full-width"
        *ngIf="form.country?.is_region_available; else nonEaeuRegion"
      >
        <mat-label>Регион</mat-label>
        <app-region-search
          name="region"
          [(ngModel)]="form.region"
          (ngModelChange)="handleRegionChange()"
          [countryId]="form.country?.id"
          [disabled]="!form.country"
          placeholder="Начните вводить"
          required
        >
        </app-region-search>
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>
      <ng-template #nonEaeuRegion>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Регион</mat-label>
          <input
            matInput
            placeholder="Введите название региона"
            name="region-non-eaeu"
            [(ngModel)]="form.regionName"
            [disabled]="!form.country"
            required
          />
        </mat-form-field>
      </ng-template>
      <mat-form-field appearance="fill" class="full-width" *ngIf="form.country?.is_region_available; else nonEaeuCity">
        <mat-label>Город</mat-label>
        <app-city-search
          name="city"
          [(ngModel)]="form.city"
          [countryId]="form.country?.id"
          [regionId]="form.region?.id"
          [disabled]="!form.region"
          placeholder="Начните вводить"
          required
        >
        </app-city-search>
        <mat-error>Поле должно быть заполнено</mat-error>
      </mat-form-field>
      <ng-template #nonEaeuCity>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Город</mat-label>
          <input
            matInput
            placeholder="Введите название города"
            name="city-non-eaeu"
            [(ngModel)]="form.cityName"
            [disabled]="!form.country"
            required
          />
        </mat-form-field>
      </ng-template>
      <p class="small-hint">*обязательные для заполнения поля</p>
    </form>
  </app-dialog-body>

  <div mat-dialog-actions class="controls">
    <app-dialog-footer>
      <div class="agreements" [formGroup]="agreements">
        <p class="subscript">
          Передавая Компании свои персональные данные, вы соглашаетесь с
          <a app-link routerLink="/politics/personal-data" target="_blank">
            Политикой Компании в области персональных данных</a
          >.
        </p>
        <mat-checkbox formControlName="processingData">
          <a app-link routerLink="/procedures/consent-personal-data-processing" target="_blank">
            Согласие на обработку персональных данных
          </a>
        </mat-checkbox>
        <mat-checkbox formControlName="marketingMessages"
          >Согласие на получение рекламных сообщений, информации (смс, push-уведомление, на электронную
          почту)</mat-checkbox
        >
      </div>
      <div class="buttons">
        <button
          form="register-form"
          type="submit"
          app-button
          color="dark"
          size="large"
          [disabled]="templateForm.invalid || loading || agreementsInvalid"
        >
          {{ buttonText }}
        </button>
        <ng-content></ng-content>
      </div>
    </app-dialog-footer>
  </div>
</ng-container>
