import { Grade } from 'common/models/grade'

/** Grade type to original english translation */
export const GRADE_LONG_ENG: Record<Grade, string> = {
  GradeClient: 'Client',
  GradePassivePartner: 'Partner',
  GradeActivePartner: 'Active Partner',
  GradeBronze: 'Bronze',
  GradeBronzePro: 'Bronze Pro',
  GradeSilver: 'Silver',
  GradeSilverPro: 'Silver Pro',
  GradeGold: 'Gold',
  GradeGoldPro: 'Gold Pro',
  GradePlatinum: 'Platinum',
  GradePlatinumPro: 'Platinum Pro',
  GradeDiamond: 'Diamond',
  GradeDiamondBlue: 'Diamond Blue',
  GradeDiamondRed: 'Diamond Red'
}

/** Grade type to short english translation */
export const GRADE_SHORT_ENG: Record<Grade, string> = {
  GradeClient: 'C',
  GradePassivePartner: 'P',
  GradeActivePartner: 'AP',
  GradeBronze: 'B',
  GradeBronzePro: 'BP',
  GradeSilver: 'S',
  GradeSilverPro: 'SP',
  GradeGold: 'G',
  GradeGoldPro: 'GP',
  GradePlatinum: 'PT',
  GradePlatinumPro: 'PP',
  GradeDiamond: 'D',
  GradeDiamondBlue: 'DB',
  GradeDiamondRed: 'DR'
}

/**
 *  GradeClient: 'GradeClient' as Grade,
 *     GradePassivePartner: 'GradePassivePartner' as Grade,
 *     GradeActivePartner: 'GradeActivePartner' as Grade,
 *     GradeBronze: 'GradeBronze' as Grade,
 *     GradeBronzePro: 'GradeBronzePro' as Grade,
 *     GradeSilver: 'GradeSilver' as Grade,
 *     GradeSilverPro: 'GradeSilverPro' as Grade,
 *     GradeGold: 'GradeGold' as Grade,
 *     GradeGoldPro: 'GradeGoldPro' as Grade,
 *     GradePlatinum: 'GradePlatinum' as Grade,
 *     GradePlatinumPro: 'GradePlatinumPro' as Grade,
 *     GradeDiamond: 'GradeDiamond' as Grade
 */
export const GRADE_LIST_ASC: Grade[] = [
  Grade.GradeClient,
  Grade.GradePassivePartner,
  Grade.GradeActivePartner,
  Grade.GradeBronze,
  Grade.GradeBronzePro,
  Grade.GradeSilver,
  Grade.GradeSilverPro,
  Grade.GradeGold,
  Grade.GradeGoldPro,
  Grade.GradePlatinum,
  Grade.GradePlatinumPro,
  Grade.GradeDiamond
]

export const gradeCompare = (a: Grade, b: Grade) => GRADE_LIST_ASC.indexOf(a) - GRADE_LIST_ASC.indexOf(b)

export const maxGrade = (a: Grade, b: Grade) => (gradeCompare(a, b) > 0 ? a : b)

export const isGradeGreaterOrEqual = (currentGrade: Grade, compareWithGrade: Grade) => {
  const currentGradeIndex = GRADE_LIST_ASC.indexOf(currentGrade)
  const compareWithGradeIndex = GRADE_LIST_ASC.indexOf(compareWithGrade)

  return currentGradeIndex >= compareWithGradeIndex
}
