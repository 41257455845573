import { Injectable } from '@angular/core'
import { CartItem } from 'common/models/cart-item'
import { RequestService } from 'common/services/request.service'
import { Observable } from 'rxjs'
import { UpdateDeliveryInCartRequest } from '../../../../../common/models/update-delivery-in-cart-request'
import { AddCartCityRequest } from '../../../../../common/models/add-cart-city-request'
import { AddCartDeliveryMethodRequest } from '../../../../../common/models/add-cart-delivery-method-request'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'
import { UpdateCountryInCartRequest } from '../../../../../common/models/update-country-in-cart-request'
import { UpdatePersonalDataInCartRequest } from '../../../../../common/models/update-personal-data-in-cart-request'
import { AddCartRegionRequest } from '../../../../../common/models/add-cart-region-request'
import { Country } from '../../../../../common/models/country'
import { OrderIntIdRequest } from 'common/models/order-int-id-request'

type Id = number
type Amount = number

@Injectable({
  providedIn: 'root'
})
export class CartService {
  constructor(private readonly requestService: RequestService) {}

  addToCart(itemId: number): Observable<CartWithProducts> {
    return this.requestService.post<CartItem, CartWithProducts>('api/cart/update', {
      item_id: itemId,
      quantity: 1
    })
  }

  approveGroupOrderRequest(req: OrderIntIdRequest) {
    return this.requestService.post<OrderIntIdRequest, CartWithProducts>('api/orders/group_order/confirm', req)
  }

  getCart(): Observable<CartWithProducts> {
    return this.requestService.post<unknown, CartWithProducts>('api/cart/full_data', {})
  }

  updateCartItem(item: CartItem): Observable<CartWithProducts> {
    return this.requestService.post<CartItem, CartWithProducts>('api/cart/update', item)
  }

  deleteCartItem(itemId: number): Observable<CartWithProducts> {
    return this.requestService.post<Pick<CartItem, 'item_id'>, CartWithProducts>('api/cart/delete', {
      item_id: itemId
    })
  }

  updateManyCartItems(idAmountMap: Map<Id, Amount>) {
    const requestItems: CartItem[] = []

    for (const [id, amount] of idAmountMap.entries()) {
      requestItems.push({ item_id: id, quantity: amount })
    }

    return this.requestService.post<{ products: CartItem[] }, CartWithProducts>('api/cart/update_multi', {
      products: requestItems
    })
  }

  estimateDelivery(req: UpdateDeliveryInCartRequest): Observable<CartWithProducts> {
    return this.requestService.post('/api/cart/estimate_delivery', req)
  }

  saveCity(req: AddCartCityRequest): Observable<CartWithProducts> {
    return this.requestService.post('/api/cart/save_city', req)
  }

  updateCountry(req: UpdateCountryInCartRequest): Observable<CartWithProducts> {
    return this.requestService.post('/api/cart/update_country', req)
  }

  saveRegion(req: AddCartRegionRequest): Observable<CartWithProducts> {
    return this.requestService.post('/api/cart/save_region', req)
  }

  saveDeliveryMethod(req: AddCartDeliveryMethodRequest): Observable<CartWithProducts> {
    return this.requestService.post('/api/cart/save_delivery_method', req)
  }

  clearCart() {
    return this.requestService.post<unknown, CartWithProducts>('/api/cart/clear', {})
  }

  updatePersonalInfo(info: UpdatePersonalDataInCartRequest): Observable<CartWithProducts> {
    return this.requestService.post<UpdatePersonalDataInCartRequest, CartWithProducts>(
      'api/cart/update_personal_data',
      info
    )
  }

  updateStock(id: number) {
    return this.requestService.post<{ stock_id: number }, CartWithProducts>('/api/cart/update_stock', {
      stock_id: id
    })
  }

  updateCurrency(id: string) {
    return this.requestService.post<{ currency_id: string }, CartWithProducts>('/api/cart/update_currency', {
      currency_id: id
    })
  }

  updateCartTotalPriceCurrency(): Observable<any> {
    return this.requestService.post<unknown, Observable<any>>('/api/orders/calculation', {})
  }

  changeCountryById(country: Country): Observable<CartWithProducts> {
    return this.requestService.post<unknown, CartWithProducts>('/api/cart/update_user_country', {
      country_id: country.id
    })
  }
}
