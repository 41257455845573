import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatRippleModule } from '@angular/material/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

const colorToRippleColor = {
  dark: '#ffffff35',
  black: '#ffffff35',
  white: '#00000035',
  pink: '##E0008D'
}

/** @deprecated use ewa-button directive */
@Component({
  selector: '[app-button]',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, MatRippleModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'getButtonClassNames()'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnChanges {
  @Input() color: 'dark' | 'pink' | 'white' | 'black' = 'dark'
  @Input() size: 'large' | 'medium' | 'small' = 'medium'
  @Input() variant: 'outlined' | 'filled' = 'filled'
  @Input() pill = false
  @Input() loading: boolean | null = false

  rippleColor!: string

  ngOnChanges(): void {
    this.setRippleColor()
  }

  getButtonClassNames(): string[] {
    const classNames: string[] = ['app-ewa-button', this.size]

    if (this.variant === 'outlined') {
      classNames.push(this.variant)
    } else if (this.color === 'black') {
      classNames.push(this.color)
    } else {
      classNames.push(`${this.color}-${this.variant}`)
    }

    this.pill && classNames.push('pill')

    return classNames
  }

  private setRippleColor() {
    this.rippleColor = colorToRippleColor[this.color]
  }
}
