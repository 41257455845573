<mat-form-field appearance="fill" class="phone-input">
  <mat-label>{{ label | translate }}</mat-label>
  <div *ngIf="country !== null" class="prefix" matTextPrefix>
    <img [src]="'https://flagcdn.com/48x36/' + country + '.png'" width="24" height="18" [alt]="country" />
  </div>
  <input
    matInput
    [formControl]="phoneInputForm"
    [required]="isRequired"
    prefix="+"
    mask="0*"
    type="text"
    inputmode="tel"
    placeholder="aboba"
  />
  <mat-error *ngIf="inputMessageError">{{ inputMessageError | translate }}</mat-error>
</mat-form-field>
